<template>
   <ManagementAccountLogs :current-id="currentId"/>
</template>

<script>
import { defineComponent, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import ManagementAccountLogs from '@/components/ManagementAccountLogs';

export default defineComponent({
  components: {
    ManagementAccountLogs,
  },
  setup() {
    const route = useRoute();

    const currentId = computed(() => {
      return route.params.uid
    });

    return {
      currentId
    }
  }
});
</script>